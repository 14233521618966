export const environment = {
  production: false,
  api: {
    serverUrl: 'https://bytardag-dev.etnol.it/api',
  },
  auth0: {
    domain: 'dev-6g67m8l3l7tmej54.eu.auth0.com',
    clientId: '7T7mA0XNrn1yszU38AT4gV7V1oZ9pRFU',
    authorizationParams: {
      audience: 'https://bytardag-dev.etnol.it/api',
      redirect_uri: 'https://bytardag-dev.etnol.it/callback',
    },
    errorPath: '/callback',
  },
};
